import axiosInstance from "app/interceptors";

export const getDashboard = async (params, signal) => {
  const data = {
    result: {
      all_total: [
        {
          division: "Satuan Pemeriksaan Intern",
          category: "Peraturan Dirut",
          value: 25,
        },
        {
          division: "Satuan Pemeriksaan Intern",
          category: "Keputusan Dirut",
          value: 25,
        },
        {
          division: "Satuan Pemeriksaan Intern",
          category: "Surat Edaran",
          value: 25,
        },
        {
          division: "Divisi Anggaran dan Akuntansi",
          category: "Peraturan Dirut",
          value: 25,
        },
        {
          division: "Divisi Anggaran dan Akuntansi",
          category: "Keputusan Dirut",
          value: 25,
        },
        {
          division: "Divisi Anggaran dan Akuntansi",
          category: "Surat Edaran",
          value: 25,
        },
        {
          division: "Divisi Umum dan Sumber Daya Manusia",
          category: "Peraturan Dirut",
          value: 25,
        },
        {
          division: "Divisi Umum dan Sumber Daya Manusia",
          category: "Keputusan Dirut",
          value: 25,
        },
        {
          division: "Divisi Umum dan Sumber Daya Manusia",
          category: "Surat Edaran",
          value: 25,
        },
        {
          division: "Divisi Monitoring dan Evaluasi",
          category: "Peraturan Dirut",
          value: 25,
        },
        {
          division: "Divisi Monitoring dan Evaluasi",
          category: "Keputusan Dirut",
          value: 25,
        },
        {
          division: "Divisi Monitoring dan Evaluasi",
          category: "Surat Edaran",
          value: 25,
        },
      ],
      total_dirut: [
        {
            division: "Satuan Pemeriksaan Intern",
            status: "Baru",
            value: 25,
          },
          {
            division: "Satuan Pemeriksaan Intern",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Satuan Pemeriksaan Intern",
            status: "Baru",
            value: 25,
          },
          {
            division: "Divisi Anggaran dan Akuntansi",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Divisi Anggaran dan Akuntansi",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Divisi Anggaran dan Akuntansi",
            status: "Baru",
            value: 25,
          },
          {
            division: "Divisi Umum dan Sumber Daya Manusia",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Divisi Umum dan Sumber Daya Manusia",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Divisi Umum dan Sumber Daya Manusia",
            status: "Baru",
            value: 25,
          },
          {
            division: "Divisi Monitoring dan Evaluasi",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Divisi Monitoring dan Evaluasi",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Divisi Monitoring dan Evaluasi",
            status: "Baru",
            value: 25,
          },
      ],
      total_kep_dirut: [
        {
            division: "Satuan Pemeriksaan Intern",
            status: "Baru",
            value: 25,
          },
          {
            division: "Satuan Pemeriksaan Intern",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Satuan Pemeriksaan Intern",
            status: "Baru",
            value: 25,
          },
          {
            division: "Divisi Anggaran dan Akuntansi",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Divisi Anggaran dan Akuntansi",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Divisi Anggaran dan Akuntansi",
            status: "Baru",
            value: 25,
          },
          {
            division: "Divisi Umum dan Sumber Daya Manusia",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Divisi Umum dan Sumber Daya Manusia",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Divisi Umum dan Sumber Daya Manusia",
            status: "Baru",
            value: 25,
          },
          {
            division: "Divisi Monitoring dan Evaluasi",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Divisi Monitoring dan Evaluasi",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Divisi Monitoring dan Evaluasi",
            status: "Baru",
            value: 25,
          },
      ],
      total_edaran: [
        {
            division: "Satuan Pemeriksaan Intern",
            status: "Baru",
            value: 25,
          },
          {
            division: "Satuan Pemeriksaan Intern",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Satuan Pemeriksaan Intern",
            status: "Baru",
            value: 25,
          },
          {
            division: "Divisi Anggaran dan Akuntansi",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Divisi Anggaran dan Akuntansi",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Divisi Anggaran dan Akuntansi",
            status: "Baru",
            value: 25,
          },
          {
            division: "Divisi Umum dan Sumber Daya Manusia",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Divisi Umum dan Sumber Daya Manusia",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Divisi Umum dan Sumber Daya Manusia",
            status: "Baru",
            value: 25,
          },
          {
            division: "Divisi Monitoring dan Evaluasi",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Divisi Monitoring dan Evaluasi",
            status: "Selesai",
            value: 25,
          },
          {
            division: "Divisi Monitoring dan Evaluasi",
            status: "Baru",
            value: 25,
          },
      ],
      all_done_total: [
        {
          division: "Satuan Pemeriksaan Intern",
          category: "Peraturan Dirut",
          value: 25,
        },
        {
          division: "Satuan Pemeriksaan Intern",
          category: "Keputusan Dirut",
          value: 25,
        },
        {
          division: "Satuan Pemeriksaan Intern",
          category: "Surat Edaran",
          value: 25,
        },
        {
          division: "Divisi Anggaran dan Akuntansi",
          category: "Peraturan Dirut",
          value: 25,
        },
        {
          division: "Divisi Anggaran dan Akuntansi",
          category: "Keputusan Dirut",
          value: 25,
        },
        {
          division: "Divisi Anggaran dan Akuntansi",
          category: "Surat Edaran",
          value: 25,
        },
        {
          division: "Divisi Umum dan Sumber Daya Manusia",
          category: "Peraturan Dirut",
          value: 25,
        },
        {
          division: "Divisi Umum dan Sumber Daya Manusia",
          category: "Keputusan Dirut",
          value: 25,
        },
        {
          division: "Divisi Umum dan Sumber Daya Manusia",
          category: "Surat Edaran",
          value: 25,
        },
        {
          division: "Divisi Monitoring dan Evaluasi",
          category: "Peraturan Dirut",
          value: 25,
        },
        {
          division: "Divisi Monitoring dan Evaluasi",
          category: "Keputusan Dirut",
          value: 25,
        },
        {
          division: "Divisi Monitoring dan Evaluasi",
          category: "Surat Edaran",
          value: 25,
        },
      ],
    },
  };
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(data), 2500);
  });
  //   const {data} = await axiosInstance.get(`/hukum-peraturan/v1/dashboard`, { params, signal });
  //   return data;
};

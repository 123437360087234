import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formValues: {},
  selectedRules: [],
};

export const regulationSlice = createSlice({
  name: "regulations",
  initialState,
  reducers: {
    setFormValues: (state, action) => {
      state.formValues = action.payload;
    },
    setSelectedRules: (state, action) => {
      state.selectedRules = action.payload;
    },
  },
});

export const { setFormValues, setSelectedRules } = regulationSlice.actions;

export default regulationSlice.reducer;

import axiosInstance from "app/interceptors";

export const getPreparations = async (params, signal) => {
  const { data } = await axiosInstance.get(`/hukum-peraturan/v1/regulation`, {
    params,
    signal,
  });
  return data;
};

export const getOnePreparation = async (id, params, signal) => {
  const { data } = await axiosInstance.get(
    `/hukum-peraturan/v1/regulation/${id}`,
    { params, signal }
  );
  return data;
};

export const deleteRegulation = async (id, signal) => {
  const { data } = await axiosInstance.delete(
    `/hukum-peraturan/v1/regulation/${id}`,
    { signal }
  );
  return data;
};

export const createNewRegulation = async (payload) => {
  const { data } = await axiosInstance.post(
    `/hukum-peraturan/v1/regulation`,
    payload
  );
  return data;
};

export const editRegulation = async (id, payload) => {
  const { data } = await axiosInstance.patch(
    `/hukum-peraturan/v1/regulation/${id}`,
    payload
  );
  return data;
};

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from "react";
import classNames from "classnames";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import { useNavigate } from "react-router";

const Header = ({ isSidebarOpen, toggleSidebar }) => {
  const navigate = useNavigate();

  const onLogout = () => {
    navigate("/logout");
  };

  return (
    <nav
    className={classNames(
      [
        "navbar",
        "border-b",
        "top-0",
        "fixed",
        "w-full",
        "bg-white",
        "z-[999]",
        "left-0",
      ],
      { "!left-[250px]": isSidebarOpen }
    )}
    style={{ width: `calc(100% - ${isSidebarOpen ? "250px" : "0px"})` }}
    >
      <div className="flex-1">
        <button
          type="button"
          className="text-gray-600 border-transparent bg-transparent hover:bg-transparent hover:border-transparent"
          onClick={toggleSidebar}
        >
          {isSidebarOpen ? (
            <RiMenuFoldLine className="w-[24px] h-[24px] text-gray-600" />
          ) : (
            <RiMenuUnfoldLine className="w-[24px] h-[24px] text-gray-600" />
          )}
        </button>
      </div>
      <div className="flex-none gap-2">
        <div className="dropdown dropdown-end">
          <div className="flex items-center space-x-2">
            <label tabIndex="0" className="btn btn-ghost btn-circle avatar">
              <div className="w-10 rounded-full">
                <img src="https://cdn4.iconfinder.com/data/icons/green-shopper/1068/user.png" alt="profile" />
              </div>
            </label>
          </div>
          <ul
            tabIndex="0"
            className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52"
          >
            <li onClick={onLogout}>
              <a href="javascript:void(0)">Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;

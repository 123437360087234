import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  templateContent: {},
};

export const templateSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    setTemplateContent: (state, action) => {
      state.templateContent = action.payload;
    },
  },
});

export const { setTemplateContent } = templateSlice.actions;

export default templateSlice.reducer;

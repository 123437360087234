import React, { useState, useEffect } from "react";
import { map } from "lodash";
import { useLocation, useOutletContext } from "react-router";
import { GiPlainCircle } from "react-icons/gi";
import { Link } from "react-router-dom";

import image from "../../../assets/Logo.png";
import menus from "contants/Menus";
import classNames from "classnames";

const SubMenu = (props) => {
  const location = useLocation();
  const { userAccessRights } = useOutletContext();
  const { menuKey, isOpen, icon, label, path, id } = props;

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    if (!isMenuOpen && !isSet) {
      const idx = props.children.findIndex(x => location.pathname.includes(x.path));
      if (idx > -1) setMenuOpen(true);
      else if (location.pathname === "/" && id === "regulations") setMenuOpen(true);
    }
  }, [location.pathname, props.children, isMenuOpen, isSet, id]);

  return (
    <li
      key={menuKey}
      className="cursor-pointer collapse collapse-arrow !outline-none"
    >
      <input
        type="checkbox"
        className="!min-h-0 w-full h-full"
        checked={isMenuOpen}
        onChange={() => {
          setIsSet(true);
          setMenuOpen((curr) => !curr);
        }}
      />
      <div
        className={`collapse-title !min-h-0 !p-2 font-semibold text-sm text-white rounded-lg flex items-center ${
          !isOpen ? "justify-center" : ""
        } hover:bg-primary-700 ${
          (props.children.findIndex(x => location.pathname.includes(x.path)) > -1 || (location.pathname === "/" && id === "regulations")) &&
          "bg-primary-700"
        }`}
      >
        {icon}
        {isOpen && (
          <span className="ml-3">
            {label}
          </span>
        )}
      </div>
      <div className="collapse-content mt-[10px] !p-0 space-y-[10px]">
        {props.children.map((child, key) => userAccessRights?.[child.id]?.canView && (
          <Link
            key={key}
            to={child.path}
            className={classNames(
              "flex space-x-2 items-center py-2 px-3 bg-gray-900 rounded-md hover:!bg-primary-800",
              {
                "!bg-primary-800":
                  location.pathname.includes(child.path) ||
                  (location.pathname === "/" && child.id === "preparations"),
              }
            )}
          >
            <GiPlainCircle className="text-primary-300" size={8} />
            <div className="text-white font-semibold text-sm">
              {child.label}
            </div>
          </Link>
        ))}
      </div>
    </li>
  );
};

const Sidebar = ({ isSidebarOpen }) => {
  const location = useLocation();
  const { userAccessRights } = useOutletContext();
  return (
    <aside
      className="fixed left-0 top-0 bottom-0 flex-col hidden sm:flex overflow-hidden border-gray-500 bg-gray-900"
      style={{
        boxShadow: "1px 1px 5px 1px #0000001F",
        flex: `0 0 ${isSidebarOpen ? "250px" : "0px"}`,
        maxWidth: `${isSidebarOpen ? "250px" : "0px"}`,
        minWidth: `${isSidebarOpen ? "250px" : "0px"}`,
        width: `${isSidebarOpen ? "250px" : "0px"}`,
        transition: "all .2s",
        zIndex: 2,
      }}
    >
      <div
        className="ml-4 mt-8 flex relative items-center"
      >
        <div className="flex-1">
          <img src={image} alt="logo" className="w-full h-auto max-w-[140px]" />
        </div>
      </div>
      <div
        className={"w-full mt-6 pt-[13px] pb-[13px] text-center bg-gray-700 text-gray-300 text-sm"}
      >
        Hukum & Peraturan
      </div>
      <ul className="flex-2 space-y-2 m-4 overflow-y-auto">
        {map(menus, (menu, key) => {
          if (menu.children?.length > 0) {
            const hasChildAccess = menu.children.map(child => {
              return userAccessRights?.[child.id].canView
            });
            if (hasChildAccess.some(x => Boolean(x))) {
              return <SubMenu key={key} menuKey={key} isOpen={isSidebarOpen} {...menu} />;
            }
          }
          if (userAccessRights?.[menu.id]?.canView) {
            return (
              <li key={key} className="cursor-pointer">
                <Link
                  to={menu.path}
                  className={`p-2 font-semibold text-sm text-white rounded-lg flex items-center hover:bg-primary-700 ${
                    (location?.pathname?.includes(menu.path) ||
                      (location.pathname === "/" &&
                        menu.id === "preparations")) &&
                    "bg-primary-700"
                  }`}
                >
                  {menu.icon}
                  <span className="ml-3">
                    {menu.label}
                  </span>
                </Link>
              </li>
            );
          }
        })}
      </ul>
      <div
        className="text-white font-normal text-sm flex-1 flex items-end justify-center mb-4"
      >
        © BPDLH 2023, Version 1.0
      </div>
    </aside>
  );
};

export default Sidebar;

import React from 'react'

const StatusBadge = ({ children, status }) => {
  let defaultDotClass = "bg-gray-500";
  let defaultWrapperClass = "bg-gray-50 text-gray-700";

  switch (status) {
    case "Baru":
      defaultDotClass = "bg-bluelight-500";
      defaultWrapperClass = "bg-bluelight-50 text-bluelight-700";
      break;
    case "Permohonan Review Lengkap":
      defaultDotClass = "bg-indigo-500";
      defaultWrapperClass = "bg-indigo-50 text-indigo-700";
      break;
    case "Proses Pembahasan Bersama":
      defaultDotClass = "bg-purple-500";
      defaultWrapperClass = "bg-purple-50 text-purple-700";
      break;
    case "Proses Persetujuan Direktur Utama":
      defaultDotClass = "bg-pink-500";
      defaultWrapperClass = "bg-pink-50 text-pink-700";
      break;
    case "Pelaporan":
      defaultDotClass = "bg-warning-500";
      defaultWrapperClass = "bg-warning-50 text-warning-700";
      break;
    case "Proses Paraf Direktorat Terkait":
      defaultDotClass = "bg-cyan-500";
      defaultWrapperClass = "bg-cyan-50 text-cyan-700";
      break;
    case "Proses Persetujuan Direktur Utama":
      defaultDotClass = "bg-amber-500";
      defaultWrapperClass = "bg-amber-50 text-amber-700";
      break;
    case "Tolak":
      defaultDotClass = "bg-red-500";
      defaultWrapperClass = "bg-red-50 text-red-700";
      break;
      
    default:
      defaultDotClass = "bg-gray-500";
      defaultWrapperClass = "bg-gray-50 text-gray-700";
      break;
  }
  return (
    <div className={`badge border-transparent p-2 text-xs ${defaultWrapperClass}`}>
      <div className={`rounded-[50%] w-2 h-2 inline-block mr-1 ${defaultDotClass}`} />
      {status}
    </div>
  )
}

export default StatusBadge;
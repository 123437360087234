import React from "react";
import classNames from "classnames";

const TitleText = ({ children, className="" }) => {
  return (
    <p
      className={
        classNames([
          "text-gray-900", "mb-0", "font-semibold", "text-lg",
          ...className.split(" ")
        ])
      }
    >
      {children}
    </p>
  );
};

export default TitleText;

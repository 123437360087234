import axiosInstance from 'app/interceptors';

export const getAgendas = async (params, signal) => {
  const {data} = await axiosInstance.get(`/hukum-peraturan/v1/schedule`, { params, signal });
  return data;
}

export const getOneAgenda = async (id, params, signal) => {
  const {data} = await axiosInstance.get(`/hukum-peraturan/v1/schedule/${id}`, { params, signal });
  return data;
}

export const createNewAgenda = async (id, payload) => {
  const {data} = await axiosInstance.patch(`/hukum-peraturan/v1/schedule/${id}`, payload);
  return data;
}
import axiosInstance from 'app/interceptors';

export const getCases = async (params, signal) => {
  const {data} = await axiosInstance.get(`/hukum-peraturan/v1/handling_case`, { params, signal });
  return data;
}

export const getOneCase = async (id, params, signal) => {
  const {data} = await axiosInstance.get(`/hukum-peraturan/v1/handling_case/${id}`, { params, signal });
  return data;
}

export const deleteCase = async (id, signal) => {
  const {data} = await axiosInstance.delete(`/hukum-peraturan/v1/handling_case/${id}`, { signal });
  return data;
}

export const createNewCase = async (payload) => {
  const {data} = await axiosInstance.post(`/hukum-peraturan/v1/handling_case`, payload);
  return data;
}

export const editCase = async (id, payload) => {
  const {data} = await axiosInstance.patch(`/hukum-peraturan/v1/handling_case/${id}`, payload);
  return data;
}

import { FaChartBar } from "react-icons/fa";
import { RiFolderOpenLine, RiTodoLine, RiPagesLine } from "react-icons/ri";

const MENU_ITEMS = [
  {
    icon: <FaChartBar className="text-white w-[18px] h-[20px]" />,
    id: "preparations",
    label: "Dashboard",
    path: "/dashboard",
  },
  {
    icon: <RiTodoLine className="text-white w-[18px] h-[20px]" />,
    id: "preparations",
    label: "Hukum & Peraturan",
    path: "/regulations",
    children: [
      {
        id: "preparations",
        label: "Penyusunan Peraturan",
        path: "/preparations",
      },
      {
        id: "agendas",
        label: "Agenda Rapat",
        path: "/agendas",
      },
    ],
  },
  {
    icon: <RiPagesLine className="text-white w-[18px] h-[20px]" />,
    id: "finalization",
    label: "Finalisasi Hukum Peraturan",
    path: "/finalization",
  },
  {
    icon: <RiPagesLine className="text-white w-[18px] h-[20px]" />,
    id: "cases-handling",
    label: "Penanganan Kasus Hukum",
    path: "/cases-handling",
  },
  {
    icon: <RiFolderOpenLine className="text-white w-[18px] h-[20px]" />,
    id: "established-regulations",
    label: "Daftar Peraturan yang Sudah Ditetapkan",
    path: "/established-regulations",
  },
];

export default MENU_ITEMS;

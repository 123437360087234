import { useCookies } from "react-cookie";
import moment from 'moment';
import Button from "components/atoms/Button";

function CookieConsentBanner() {
    const [cookies, setCookie] = useCookies(["hkp-cookie-consent"]);
  function giveCookie() {
    setCookie('hkp-cookie-consent', '1', { path: '/', expires: moment().add(1, 'year').toDate() });
  }
  return (
    <div className="flex flex-wrap items-center gap-x-5 gap-y-3 p-5 fixed bottom-8 left-6 z-[99] bg-white rounded-xl w-2/6 shadow-md">
      <div className="text-gray-800 flex-1">
        We use cookies to enhance your user experience. By using our website, you agree to our use of cookies.{' '}
        <a href="/" className="underline text-[#007aff]">Learn more.</a>
      </div>
      <Button
        className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
        onClick={giveCookie}
      >
        Accept
      </Button>
    </div>
  );
}

export default CookieConsentBanner;

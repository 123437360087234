import axiosInstance from "app/interceptors";

export const fileService = async (payload) => {
  const { data } = await axiosInstance.post(`/file-service/v1/files`, payload, {
    params: {
      service_name: "mis-hukum-peraturan"
    },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

export const getRoles = async (params, signal) => {
  const {data} = await axiosInstance.get(`/hukum-peraturan/v1/master/roles`, { params, signal });
  return data;
}

export const getEmployee = async (params, signal) => {
  const {data} = await axiosInstance.get(`/hukum-peraturan/v1/master/employee`, { params, signal });
  return data;
}
import React from 'react';

const Dashboard = React.lazy(() => import('pages/Dashboard'));
const Preparations = React.lazy(() => import('pages/Preparations'));
const NewPreparations = React.lazy(() => import('pages/Preparations/new'));
const EditPreparations = React.lazy(() => import('pages/Preparations/edit'));
const DetailPreparation = React.lazy(() => import('pages/Preparations/detail'));

const NewCaseHandling = React.lazy(() => import('pages/CasesHandling/new'));
const EditCaseHandling = React.lazy(() => import('pages/CasesHandling/edit'));
const DetailCaseHandling = React.lazy(() => import('pages/CasesHandling/detail'));
const CaseHandling = React.lazy(() => import('pages/CasesHandling'));

const Agendas = React.lazy(() => import('pages/Agendas'));

const Finalization = React.lazy(() => import('pages/Finalization'));
const DetailFinalization = React.lazy(() => import('pages/Finalization/detail'));

const EstablishedRegulation = React.lazy(() => import('pages/Established'));
const DetailEstablishedRegulation = React.lazy(() => import('pages/Established/detail'));

const routesName = [
  {
    path: "/established-regulations",
    element: EstablishedRegulation,
    exact: true,
    key: "established-regulations",
    type: "canView",
  },
  {
    path: "/established-regulations/:preparationId/detail",
    element: DetailEstablishedRegulation,
    exact: true,
    key: "established-regulations",
    type: "canView",
  },
  {
    path: "/finalization",
    element: Finalization,
    exact: true,
    key: "finalization",
    type: "canView",
  },
  {
    path: "/finalization/:preparationId/detail",
    element: DetailFinalization,
    exact: true,
    key: "finalization",
    type: "canView",
  },
  {
    path: "/",
    element: Preparations,
    exact: true,
    key: "preparations",
    type: "canView",
  },
  {
    path: "/preparations",
    element: Preparations,
    exact: true,
    key: "preparations",
    type: "canView",
  },
  {
    path: "/preparations/new",
    element: NewPreparations,
    exact: true,
    key: "preparations",
    type: "canCreate",
  },
  {
    path: "/preparations/:preparationId/edit",
    element: EditPreparations,
    exact: true,
    key: "preparations",
    type: "canUpdate",
  },
  {
    path: "/preparations/:preparationId/detail",
    element: DetailPreparation,
    exact: true,
    key: "preparations",
    type: "canView",
  },
  {
    path: "/cases-handling",
    element: CaseHandling,
    exact: true,
    key: "cases-handling",
    type: "canView",
  },
  {
    path: "/cases-handling/new",
    element: NewCaseHandling,
    exact: true,
    key: "cases-handling",
    type: "canCreate",
  },
  {
    path: "/cases-handling/:caseId/edit",
    element: EditCaseHandling,
    exact: true,
    key: "cases-handling",
    type: "canUpdate",
  },
  {
    path: "/cases-handling/:caseId/detail",
    element: DetailCaseHandling,
    exact: true,
    key: "cases-handling",
    type: "canView",
  },
  {
    path: "/agendas",
    element: Agendas,
    exact: true,
    key: "agendas",
    type: "canView",
  },
  {
    path: "/dashboard",
    element: Dashboard,
    exact: true,
    key: "preparations",
    type: "canView",
  },
];

export default routesName;
